<template>
  <Frame title="Configuración" titleAction="Roles">
    <div slot="actions">
      <span class="close-frame" @click="goBack">
        <i class="mdi mdi-close"></i>
      </span>
    </div>
    <Title title="" description="Indica el nombre y descripción del rol" />
    <Layout>
      <Column size="4">
        <Field label="Nombre del rol">
          <Input :required="true" v-model="model.RoleName" rules="required" />
        </Field>
      </Column>
      <Column size="6">
        <Field label="Descripción">
          <Input :required="true" v-model="model.Description" rules="required" />
        </Field>
      </Column>
    </Layout>
    <hr />
    <Layout>
      <Column align="right">
        <Button @click="goBack" color="terciary-outlined"> Cancelar </Button>
        <Button v-if="$can('EditEmployerCompany', 'STM')" color="secondary" @click="onSave">
          Guardar
        </Button>
      </Column>
    </Layout>
  </Frame>
</template>

<script>
export default {
  name: 'ConfigAddRole',
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        RoleName: null,
        Description: null,
      },
    };
  },
  mounted() {
    // if (this.modelToEdit) {
    //   this.titleAction = 'Ver';
    //   this.model = { ...this.modelToEdit };
    // }
  },
  methods: {
    async onSave() {
      const isValid = await this.isValid();
      if (!isValid) {
        return;
      }
      this.$post('Configuration/Role', this.model, 'stamp')
        .then(({ data }) => {
          if (data) {
            if (data.operationResult > 0) {
              this.setNotification(
                'El rol han sido guardado exitosamente. No olvides asignar los permisos que aplicarán para este rol.',
                'success',
                'Rol guardado exitosamente'
              );
              this.$router.push({
                name: 'viewRole',
                params: {
                  id: data.operationMessage,
                },
              });
            } else {
              this.setNotification(data.operationMessage, 'warning');
            }
          }
        })
        .catch(() => {
          this.setNotification('Ocurrió un error inesperado', 'warning');
        });
      // this.$parent.close()
    },
    onUpdate() {
      this.setNotification('Actualizado correctamente', 'success');
      // console.log(this.model);
      // this.$parent.close()
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
